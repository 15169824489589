import { render, staticRenderFns } from "./Completion.vue?vue&type=template&id=6bb55c18&"
import script from "./Completion.vue?vue&type=script&lang=js&"
export * from "./Completion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/projects/compliance-apex-lite-pentest/resources/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6bb55c18')) {
      api.createRecord('6bb55c18', component.options)
    } else {
      api.reload('6bb55c18', component.options)
    }
    module.hot.accept("./Completion.vue?vue&type=template&id=6bb55c18&", function () {
      api.rerender('6bb55c18', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/steps/Completion.vue"
export default component.exports